<template>
  <div class="landingPage">
    <!-- <nav>
      <div class="inner flexB">
        <div class="logo">
          <router-link to="/"
            ><img src="@/assets/images/logo.svg" alt="로고"
          /></router-link>
        </div>
        <router-link class="loginBtn" to="/account/login">로그인</router-link>
      </div>
    </nav> -->
    <section class="titleSection">
      <div class="inner flex">
        <div class="left">
          <div class="logo">
            <img src="@/assets/images/logo_white.svg" alt="로고" />
            <h3>{{ $t("title") }}</h3>
          </div>
          <div class="flex store">
            <!-- <router-link to="/">
              <img
                src="@/assets/images/appStore_white.png"
                alt="앱스토어 바로가기"
            /></router-link> -->
            <router-link to="/">
              <img
                src="@/assets/images/googlePlay_white.png"
                alt="구글플레이스토어 바로가기"
            /></router-link>
          </div>
        </div>
        <div class="right">
          <img class="bg" src="@/assets/images/main2.png" alt="mockup" />

          <ul class="animated delay-200 delay-600">
            <li class="floating">
              <div>
                <img src="@/assets/images/chat.svg" alt="아이콘" />
                <span>AI Chat</span>
              </div>
            </li>
            <li class="floating">
              <div>
                <img src="@/assets/images/sentence.svg" alt="아이콘" />
                <span>Sentence</span>
              </div>
            </li>
            <li class="floating">
              <div>
                <img src="@/assets/images/word.svg" alt="아이콘" />
                <span>Word</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- <section class="description">
      <h2>쉽게 배우는 한국어</h2>
      <h5>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</h5>
    </section> -->
    <section class="bodySection">
      <div class="inner flex">
        <div class="left">
          <h5>AI Chat</h5>
          <h3>{{ $t("aiChat") }}</h3>
          <p>
            {{ $t("aiChatDescription") }}
          </p>
        </div>
        <div class="animated delay-200 right fadeInRight">
          <img src="@/assets/images/section2.png" alt="AIChat" />
        </div>
      </div>
    </section>
    <section class="bodySection word">
      <div class="inner flex">
        <div class="animated delay-200 right fadeInLeft">
          <img src="@/assets/images/section3.png" alt="AIChat" />
        </div>
        <div class="left">
          <h5>Word</h5>
          <h3>{{ $t("word") }}</h3>
          <p>
            {{ $t("wordDescription") }}
          </p>
        </div>
      </div>
    </section>
    <section class="bodySection sentence">
      <div class="inner flex">
        <div class="left">
          <h5>Sentence</h5>
          <h3>{{ $t("sentence") }}</h3>
          <p>
            {{ $t("sentenceDescription") }}
          </p>
        </div>
        <div class="animated delay-200 right fadeInRight">
          <img src="@/assets/images/section4.png" alt="AIChat" />
        </div>
      </div>
    </section>
    <section class="bodySection imgWrap"></section>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";

export default {
  components: { Footer },

  data() {
    return {};
  },
  mounted() {
    this.observeElements();
  },
  methods: {
    observeElements() {
      const options = {
        root: null, // 뷰포트를 루트로 사용
        threshold: 0.2, // 타겟의 10%가 보여질 때 콜백 실행
      };
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 타겟 요소가 뷰포트에 진입
            // entry.target 자체가 .flex 클래스를 가진 요소를 가리키도록 변경
            const targetChildren = entry.target.querySelectorAll(
              // ".bodySection .image, .bodySection .text"
              ".bodySection .right"
            );
            targetChildren.forEach((child) => {
              child.classList.add("go");
            });
            observer.unobserve(entry.target); // 애니메이션이 적용된 후 관찰 중지
          }
        });
      }, options);
      // 관찰 대상을 '.bodySection .flex'로 변경하여 부모 요소를 직접 타겟팅
      const elements = document.querySelectorAll(".bodySection .flex");
      elements.forEach((el) => {
        observer.observe(el);
      });
    },
  },
};
</script>
